import axios from "axios";
import { IMessenger, IMessengerApiDto, IMessengerAvitoDto } from "./types";

const URL = "messengers";

export const MESSENGERS_API = {
  async getMessengers() {
    try {
      const res = await axios.get<IMessenger[]>(URL);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addMessenger(dto: IMessengerAvitoDto | IMessengerApiDto) {
    try {
      const res = await axios.post(URL, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeMessenger(dto: IMessenger) {
    try {
      const res = await axios.patch(URL, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async deleteMessenger(id: number) {
    try {
      const res = await axios.delete(URL + '/' + id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getConstants() {
    try {
      const res = await axios.get<{ label: string; value: string }[]>(URL + "/constants");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
