import { Form, Input } from "antd";
import { FC } from "react";

export const AvitoForm = () => {
  return (
    <>
      <Form.Item style={{ marginTop: 10 }} name="client_id" label="AVITO ID" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input  />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} name="creds_id" label="client id" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} name="creds_password" label="client_secret" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input />
      </Form.Item>
    </>
  );
};
