import { FC } from "react";
import "./index.css";
import { StatusEnum } from "../../types";
import { Popconfirm } from "antd";

interface Props {
  status: StatusEnum | "";
  processOrder: () => void;
}

const STATUS_TYPES = {
  [StatusEnum.ORDER]: "Клиент оставил контакты",
  [StatusEnum.NO_ANSWER]: "Бот не может ответить на вопрос",
};

export const ChatStatus: FC<Props> = ({ status, processOrder }) => {
  
  if (status) {
    return (
      <Popconfirm
          title="Заказ обработан?"
          description="Вы уверены, что заказ обработан?"
          onConfirm={processOrder}
          okText="Да"
          cancelText="Нет"
        >
          <span className={`status status_${status}`}>{STATUS_TYPES[status]}</span>
        </Popconfirm>
      
    );
  } else {
    return null
  }
};
