import { FC } from "react";
import './index.css';

interface Props {
  text: string;
  url?: string;
  onClick?: () => void;
}

export const Title: FC<Props> = ({ text, url, onClick }) => {
  // const click = () => {
  //   window.open(url, "_blank");
  // };
  return <span onClick={onClick} className="title">{text}</span>;
};
