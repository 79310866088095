import { Button, Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { IChat } from "./types";
import { Chat } from "./Chat/Chat";
import { Message } from "../../components/Message";
import { CHAT_API } from "./chatApi";
import { useDrawer } from "../../hooks/useDrawer";

export const ChatsPage = () => {
  const { isLoading, data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["chats"],
    ({ pageParam = 0 }) => CHAT_API.getChats(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.nextPage,
    }
  );
  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<IChat>();

  const history = useNavigate();
  const queryClient = useQueryClient();

  const clickHandler = (id: number) => {
    history(id);
  };

  const changeBotMutation = useMutation(
    async (chat_id: number) => {
      await CHAT_API.changeBotOfChat(chat_id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("chats");
      },
    }
  );

  const changeBotHandler = async (chat_id: number) => {
    await changeBotMutation.mutateAsync(chat_id);
  };

  const clickMessageHandler = async (chat_id: number) => {
    const chat = await CHAT_API.getChat(chat_id);
    setCurItem(chat);
  };

  const processOrder = async (id: number) => {
    await CHAT_API.processOrder(id);
    queryClient.invalidateQueries("chats");
  };

  if (isLoading || !data) return null;

  return (
    <>
      <div>
        <InfiniteScroll
          dataLength={data.pages.length}
          hasMore={hasNextPage!}
          loader={<h4>Loading...</h4>}
          next={fetchNextPage}
        >
          {data.pages.map((page) =>
            page.items.map((chat: any) => (
              <Chat
                key={chat.id}
                {...chat}
                click={clickHandler}
                changeBot={() => changeBotHandler(chat.id)}
                clickMessage={() => clickMessageHandler(chat.id)}
                processOrder={() => processOrder(chat.id)}
              />
            ))
          )}
        </InfiniteScroll>
        {hasNextPage ? (
          <Button onClick={() => fetchNextPage()}>Еще диалоги</Button>
        ) : (
          <p>Больше нет диалогов</p>
        )}
      </div>
      <Drawer
        title="Чат"
        placement="right"
        onClose={onClose}
        open={isOpen}
        width="60%"
      >
        <div>
          {cur?.chats?.map((message) => (
            <Message text={message.content} role={message.role} />
          ))}
        </div>
      </Drawer>
    </>
  );
};
