import {
  SkinOutlined,
  MessageOutlined,
  FileOutlined,
  ShareAltOutlined,
  RobotOutlined,
  SettingOutlined,
  BugOutlined
} from "@ant-design/icons";
import { Menu as AntMenu, MenuProps } from "antd";
import { NavLink } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

export const LINKS = [
  // {
  //   value: "/",
  //   label: "Задачи",
  // },
  {
    value: "/goods",
    label: "Товары",
    icon: <SkinOutlined />
  },
  {
    value: "/chats",
    label: "Диалоги",
    icon: <MessageOutlined />
  },
  {
    value: "/templates",
    label: "Инструкции",
    icon: <FileOutlined />
  },
  {
    value: "/integrations",
    label: "Интеграции",
    icon: <ShareAltOutlined />
  },
  {
    value: "/config",
    label: "Конфигурация",
    icon: <RobotOutlined />
  },
  {
    value: "/sandbox",
    label: "Песочница",
    icon: <BugOutlined />
  },
  // {
  //   value: "/settings",
  //   label: "Настройки",
  //   icon: <SettingOutlined />
  // },
];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = LINKS.map((item, index) =>
  getItem(
    <NavLink className="s" to={item.value} key={index}>
      {item.label}
    </NavLink>,
    index + 1,
    item.icon
  )
);

export const Menu = () => {
  return (
    <AntMenu
      theme="dark"
      defaultSelectedKeys={["1"]}
      mode="inline"
      items={items}
      style={{ marginTop: 60 }}
    />
  );
};
