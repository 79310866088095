import { IGood } from '../Goods/types';

export interface IAIChat {
  role: "user" | "system" | "assistant";
  content: string;
}

export interface IChat {
  id: number;
  client_id: number;
  customer_id: number;
  last_message: string;
  status: StatusEnum | null;
  side: string;
  image: string;
  good: Omit<IGood, "description">;
  bot: boolean;
  updatedAt: string;
  chats?: IAIChat[]
}

export enum StatusEnum {
  ORDER = "order",
  NO_ANSWER = "no_asnswer",
}
