import { Space } from "antd";
import { IntegrationsBlock } from "./blocks/IntegrationsBlock";
import { MessengersBlock } from "./blocks/MessengersBlock";


export const IntegrationsPage = () => {
  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <MessengersBlock />
      <IntegrationsBlock />
    </Space>
  );
};
