import axios from "axios";
import { IConfig, IResult, IUrl } from "./types";

export const FEED_API = {
  async getFiles() {
    try {
      const res = await axios.get(`files`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async uploadFile(dto: any) {
    try {
      const res = await axios.post<IConfig>(`feeds/upload`, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async uploadXML(dto: any) {
    try {
      const res = await axios.post<IConfig>(`feeds/xml/file`, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async processFileFromConfig(dto: any) {
    try {
      const res = await axios.post<IResult>(`feeds`, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getUrl() {
    try {
      const res = await axios.get(`urls`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addUrl(dto: IUrl) {
    try {
      const res = await axios.post(`urls`, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeUrl(dto: IUrl) {
    try {
      const res = await axios.patch(`urls`, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async refresh() {
    try {
      const res = await axios.post(`feeds/refresh`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
