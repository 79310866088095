import { FC, useEffect, useState } from "react";
import { Button, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { GoodDTO } from "../types";

interface GoodFormProps {
  initialValues: GoodDTO | null;
  submit: (template: GoodDTO) => void;
}

export const GoodForm: FC<GoodFormProps> = ({ initialValues, submit }) => {
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleFormChange = () => {
    const res = initialValues === form.getFieldsValue() ? false : true;
    if (res !== isValuesChanged) {
      setIsValuesChanged(res);
    }
  };

  return (
    <Form
      form={form}
      onFinish={submit}
      layout="vertical"
      onValuesChange={handleFormChange}
    >
      <Form.Item name="description" label="Описание товара">
        <TextArea autoSize />
      </Form.Item>
      <Form.Item name="task" label="Инструкции только для этого товара">
        <TextArea autoSize />
      </Form.Item>
      <Form.Item>
        <Row justify="space-between">
          <Button type="primary" htmlType="submit" disabled={!isValuesChanged}>
            Сохранить
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
