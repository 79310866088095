import { FC } from "react";
import { Space, Switch } from "antd";
import { AndroidOutlined, UserOutlined } from "@ant-design/icons";
import { IChat } from "../types";
import { ChatStatus } from "./ChatStatus";
import { formatTimestamp } from "../../../utils/formatTimestamp";
import { Title } from "../../../components/Title";
import { Card } from "../../../components/Card";
import "./index.css";

interface ChatProps extends IChat {
  click: (id: number) => void;
  changeBot: () => void;
  clickMessage: () => void;
  processOrder: () => void;
}

export const Chat: FC<ChatProps> = ({
  id,
  customer_id,
  last_message,
  good,
  bot,
  status,
  updatedAt,
  side,
  image,
  changeBot,
  clickMessage,
  processOrder,
}) => {
  const clickClientId = () => {
    window.open("https://www.avito.ru/profile/messenger/channel/" + id, "_blank");
  };

  const clickGood = () => {
    window.open(good.url, "_blank");
  };

  return (
    <Card>
      <Space size={20}>
        {/* <img className="chat_image" src={image} /> */}
        <Space direction="vertical" size={5}>
          <Space>
            <Title text={`Клиент ${customer_id}`} onClick={clickClientId} />
            {good ? (
              <>
                <span> - </span>
                <Title text={good.title} onClick={clickGood} url={good.url} />
              </>
            ) : (
              <span> Нет товара </span>
            )}
          </Space>
          <div className="last_message" onClick={clickMessage}>
            <div>
              {side === "bot" ? <AndroidOutlined /> : <UserOutlined />}
              {side} {formatTimestamp(updatedAt)}
            </div>
            <div>{last_message}</div>
          </div>
          <div>
            <span>Бот </span>
            <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" checked={bot} onChange={changeBot} />
            {status && status?.length > 0 && <ChatStatus processOrder={processOrder} status={status} />}
          </div>
        </Space>
      </Space>
    </Card>
  );
};
