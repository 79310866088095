import { FC } from "react";
import { ITemplate } from "../types";
import { Button, Divider, Popconfirm, Row, Space, Switch, Tag } from "antd";
import { Card } from "../../../components/Card";

export interface ITemplateProps extends ITemplate {
  click: (id: number) => void;
  change: (id: number) => void;
  destroy: (id: number) => void;
}

export const Template: FC<ITemplateProps> = ({
  id,
  name,
  text,
  active,
  type,
  change,
  click,
  destroy,
}) => {
  const clickHandler = () => {
    click(id);
  };
  const changeHandler = () => {
    change(id);
  };
  const deleteHandler = () => {
    destroy(id);
  };

  return (
    <Card>
      <div onClick={clickHandler}>
        <Space>
          <h3>{name}</h3>
          {
            type === 0 && <Tag color="red">Основное</Tag>
          }
        </Space>
        <div>{text}</div>
      </div>
      {type !== 0 && (
        <>
          <Divider />
          <Row justify="space-between">
            <Switch
              checkedChildren="Вкл"
              unCheckedChildren="Выкл"
              checked={active}
              onChange={changeHandler}
            />
            <Popconfirm
              title="Удалить"
              description="Вы уверены, что хотите безвозвратно удалить шаблон?"
              onConfirm={deleteHandler}
              // onCancel={cancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button danger>Удалить</Button>
            </Popconfirm>
          </Row>
        </>
      )}
    </Card>
  );
};
