import axios from "axios";
import { IUserSetting } from "./types";

export const USER_API = {
  async login(creds: { login: string; password: string }) {
    try {
      const res = await axios.post("users/login", creds);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async registration(creds: { login: string; password: string }) {
    try {
      const res = await axios.post("users/registration", creds);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getUserSettings() {
    try {
      const res = await axios.get("users");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeUserSettings(dto: IUserSetting) {
    try {
      const res = await axios.patch("users", dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async turnHookOn(avito: string) {
    try {
      const res = await axios.post("users/hookon", { avito });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
