import { Upload, Input, Space, Tag, Button, Table, Drawer, Tooltip, Spin, Result } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useDrawer } from "../../hooks/useDrawer";
import { useState } from "react";
import { FEED_API } from "./feedApi";
import { useQuery } from "react-query";
import { IConfig, IFeedForm, IResult } from "./types";
import { formatTimestamp } from "../../utils/formatTimestamp";
import { GOODS_API } from "../Goods/goodsApi";
import { GoodsPage } from "../Goods/GoodsPage";
import { UrlsBlock } from "./components/UrlsBlock";

const { Dragger } = Upload;
const { TextArea } = Input;

interface DataType {
  key: string;
  createdAt: string;
  add: number;
  update: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <span>{formatTimestamp(text)}</span>,
  },
  {
    title: "Количество добавленных товаров",
    dataIndex: "added",
    key: "add",
  },
  {
    title: "Количество обновленных товаров",
    dataIndex: "updated",
    key: "update",
  },
];

export const FeedPage = () => {
  const { open, isOpen, onClose } = useDrawer();
  const [status, setStatus] = useState<"file" | "config" | "loading" | "result">("file");
  const [configData, setConfigData] = useState<IConfig | null>(null);
  const [feed, setFeed] = useState<IFeedForm>({
    title: "",
    description: "",
    good_id: "",
    avito_id: "",
    price: "",
    stock: "",
    url: "",
    changed: false,
  });
  const [result, setResult] = useState<IResult | null>(null);
  const { data: files, refetch } = useQuery<any[]>("files", FEED_API.getFiles);
  const { data: counts, refetch: refetchCounts } = useQuery("count", GOODS_API.getCounts);

  const handleBeforeUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    setStatus("loading");
    const response = await FEED_API.uploadFile(formData);
    if (response) {
      setConfigData(response);
      if (response.feed) {
        const { title, description, good_id, avito_id, stock, price, url } = response.feed;
        setFeed({
          title,
          description,
          good_id,
          avito_id,
          changed: false,
          stock,
          price,
          url,
        });
      } else {
        const headers = response.data.map((item) => item.header);
        const descFromHeaders = headers.map((item) => `{${item}}`).join("\n");
        const idFromFirstColumn = `{${headers[0]}}`;
        let newFeed: IFeedForm = {
          description: descFromHeaders,
          title: "",
          avito_id: idFromFirstColumn,
          good_id: "",
          stock: "",
          price: "",
          url: "",
          changed: false,
        };
        const firstMatchOfTitle = headers.find((value) => value.toLowerCase() === "title" || value === "name");
        if (firstMatchOfTitle) {
          newFeed = { ...newFeed, title: `{${firstMatchOfTitle}}` };
        }
        setFeed(newFeed);
      }
    }
    setStatus("config");
    // }

    return false;
  };

  const onFinish = async () => {
    if (configData) {
      const dto = {
        headers: configData.data.map((item) => item.header),
        filename: configData.filename,
        filetype: configData.filetype,
        ...feed,
      };
      setStatus("loading");
      const response = await FEED_API.processFileFromConfig(dto);
      if (response) {
        setStatus("result");
        setResult(response);
        refetch();
      }
    }
  };

  const tagClickHandler = (header: string) => {
    setFeed({ ...feed, description: `${feed.description} {${header}}` });
  };

  const turnBotHandler = async (action: "on" | "off") => {
    if (action === "on") {
      await GOODS_API.turnBotOnForAll();
    } else {
      await GOODS_API.turnBotOffForAll();
    }
    refetchCounts();
  };

  return (
    <Space size="large" direction="vertical">
      <div>
        <h3>Информация</h3>
        {counts && (
          <div className="good_info_block">
            <div>
              <span className="good_info_count">{counts.all}</span>
              <span>товаров всего</span>
            </div>
            <div>
              <span
                className={`good_info_count ${
                  counts.all === counts.withoutBot ? "good_info_count-bad" : "good_info_count-good"
                }`}
              >
                {counts.withoutBot}
              </span>
              <span>товаров без бота</span>
            </div>
          </div>
        )}
      </div>
      <div>
        <h3>Действия</h3>
        <Space>
          <Button onClick={open}>Загрузить товары из файла</Button>
          <Button onClick={() => turnBotHandler("on")}>Включить бот для всех товаров</Button>
          <Button onClick={() => turnBotHandler("off")}>Выключить бот для всех товаров</Button>
        </Space>
      </div>
      <UrlsBlock />
      <div>
        <h3>История загрузок</h3>
        <Table columns={columns} dataSource={files} />
      </div>
      <div>
        <h3>Товары</h3>
        <GoodsPage />
      </div>

      <Drawer title="Загрузка файла" placement="right" onClose={onClose} open={isOpen} width="60%">
        {status === "file" ? (
          <div>
            <Dragger beforeUpload={handleBeforeUpload} accept=".xlsx, .xml" style={{ height: 100 }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Загрузите файл</p>
              <p className="ant-upload-hint">Перетащите или выберите нужный файл формата XLSX или XML</p>
            </Dragger>
          </div>
        ) : status === "config" ? (
          <Space direction="vertical" size="large">
            <Space direction="vertical">
              <div>Составьте название и описание товара из колонок файла:</div>
              <Space wrap>
                {configData?.data.map((item: any, index) => {
                  const value =
                    item.header === "param"
                      ? item.value.map((item: any) => `${item["@_name"]}: ${item["#text"]}`).join("\n")
                      : item.value;
                  return (
                    <Tooltip title={value} key={index}>
                      <Tag onClick={() => tagClickHandler(item.header)}>{item.header}</Tag>
                    </Tooltip>
                  );
                })}
              </Space>
            </Space>
            <Space direction="vertical">
              <div>Из какой колонки брать Id товара:</div>
              <Input
                value={feed.good_id}
                onChange={(e) =>
                  setFeed({
                    ...feed,
                    good_id: e.target.value,
                    changed: true,
                  })
                }
              />
            </Space>
            <Space direction="vertical">
              <div>Из какой колонки брать AvitoId товара:</div>
              <Input
                value={feed.avito_id}
                onChange={(e) =>
                  setFeed({
                    ...feed,
                    avito_id: e.target.value,
                    changed: true,
                  })
                }
              />
            </Space>
            <Space direction="vertical">
              <div>Из какой колонки брать URL товара:</div>
              <Input
                value={feed.url}
                onChange={(e) =>
                  setFeed({
                    ...feed,
                    url: e.target.value,
                    changed: true,
                  })
                }
              />
            </Space>
            <Space direction="vertical">
              <div>Из какой колонки брать стоимость товара:</div>
              <Input
                value={feed.price}
                onChange={(e) =>
                  setFeed({
                    ...feed,
                    price: e.target.value,
                    changed: true,
                  })
                }
              />
            </Space>
            <Space direction="vertical">
              <div>Из какой колонки брать наличие товара:</div>
              <Input
                value={feed.stock}
                onChange={(e) =>
                  setFeed({
                    ...feed,
                    stock: e.target.value,
                    changed: true,
                  })
                }
              />
            </Space>
            <Space direction="vertical">
              <div>Из какой колонки брать название товара:</div>
              <Input value={feed.title} onChange={(e) => setFeed({ ...feed, title: e.target.value, changed: true })} />
            </Space>
            <Space direction="vertical" style={{ display: "flex" }}>
              <div>Описание товара с использованием значений из колонок файла:</div>
              <TextArea
                value={feed.description}
                onChange={(e) => setFeed({ ...feed, description: e.target.value, changed: true })}
                rows={12}
                style={{ marginBottom: 20 }}
              />
            </Space>
            <Button onClick={onFinish} type="primary">
              Сохранить и загрузить файл
            </Button>
          </Space>
        ) : status === "loading" ? (
          <div style={{ marginBottom: 10, marginTop: 20 }}>
            <div>
              <Spin tip="Loading" size="large">
                <div className="content" />
              </Spin>
            </div>
            <div>Загрузка файла и товаров</div>
          </div>
        ) : status === "result" ? (
          <Result
            status="success"
            title="Файл успешно загружен"
            subTitle={`Обработано ${result!.added + result!.updated} товаров. ${result!.added} добавлено. ${
              result!.updated
            } обновлено.`}
            extra={[
              <Button key="buy" onClick={onClose}>
                Закрыть
              </Button>,
            ]}
          />
        ) : null}
      </Drawer>
    </Space>
  );
};
