import { FC, useEffect } from "react";
import { Button, Form, Row, Input } from "antd";
import { TemplateDTO } from "../types";
const { TextArea } = Input;

interface TemplateFormProps {
  initialValues: TemplateDTO | null;
  submit: (template: TemplateDTO) => void;
}

export const TemplateForm: FC<TemplateFormProps> = ({ initialValues, submit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return (
    <Form form={form} onFinish={submit} layout="vertical">
      <Form.Item name="name" label="Название">
        <TextArea autoSize />
      </Form.Item>
      <Form.Item name="text" label="Текст">
        <TextArea autoSize />
      </Form.Item>
      <Form.Item>
        <Row justify="space-between">
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
