import { Button, Divider, Drawer, Input, Row, Space, Typography } from "antd";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink, useNavigate } from "react-router-dom";
import { Good } from "./components/Good/Good";
import { GOODS_API } from "./goodsApi";
import { useDrawer } from "../../hooks/useDrawer";
import { GoodDTO, IGood } from "./types";
import { GoodForm } from "./components/GoodForm";
import { ITemplate } from "../Templates/types";
import { TEMPLATES_API } from "../Templates/templatesApi";
import { useState } from "react";

export const GoodsPage = () => {
  const { isLoading, isError, error, data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(["goods"], ({ pageParam = 0 }) => GOODS_API.getGoods(pageParam, searchText), {
      getNextPageParam: (lastPage, allPages) => lastPage.nextPage,
    });
  const { data: templates } = useQuery<ITemplate[]>("templates", TEMPLATES_API.getTemplates);
  const [searchText, setSearchText] = useState("");

  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<IGood>();

  const history = useNavigate();

  const queryClient = useQueryClient();

  const changeMutation = useMutation(
    async (dto: GoodDTO) => {
      await GOODS_API.changeGood(dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("goods");
      },
    }
  );

  const changeBotMutation = useMutation(
    async (good_id: number) => {
      await GOODS_API.changeBotOfGood(good_id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("goods");
      },
    }
  );

  const fetchFromAvitoMutation = useMutation(
    async () => {
      await GOODS_API.fetchFromAvito();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("goods");
      },
    }
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
      // mutation.mutate({ name: text, hypothesisId: +id });
    }
  };

  const changeBotHandler = async (good_id: number) => {
    await changeBotMutation.mutateAsync(good_id);
  };

  const fetchFromAvitoHandler = async () => {
    await fetchFromAvitoMutation.mutateAsync();
  };

  const submitHandler = async (dto: any) => {
    if (cur) {
      await changeMutation.mutateAsync({ ...dto, id: cur.id });
    }
    onClose();
  };

  if (isLoading || !data) return null;

  return (
    <>
      <div>
        {/* <Input
          style={{ marginBottom: 40 }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={handleKeyPress}
        /> */}
        {/* <Button onClick={fetchFromAvitoHandler} loading={isLoading}>
          Загрузить новые товары с Авито
        </Button> */}
        <InfiniteScroll
          dataLength={data.pages.length}
          hasMore={hasNextPage!}
          loader={<h4>Loading...</h4>}
          next={fetchNextPage}
        >
          {data.pages.map((page) =>
            page.items.map((good: any) => (
              <Good
                key={good.id}
                {...good}
                click={() => setCurItem(good)}
                changeBot={() => changeBotHandler(good.id)}
              />
            ))
          )}
        </InfiniteScroll>
        <Row justify="space-between">
          {/* {hasNextPage ? <Button onClick={() => fetchNextPage()}>Еще товары</Button> : <p>Больше нет товаров</p>}
          <Button onClick={fetchFromAvitoHandler} loading={isLoading}>
            Загрузить новые товары с Авито
          </Button> */}
        </Row>
      </div>
      {cur && (
        <Drawer title={cur.title} placement="right" onClose={onClose} open={isOpen} width="60%">
          <GoodForm initialValues={cur} submit={submitHandler} />
          <Typography.Title level={5} style={{ margin: 0 }}>
            Общие инструкции для всех товаров
          </Typography.Title>
          <div>
            {templates
              ?.filter((template) => template.active === true)
              .map((template) => (
                <div key={template.id} className="good_template_block" onClick={() => history("/templates")}>
                  <Typography.Paragraph
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {template.text}
                  </Typography.Paragraph>
                </div>
              ))}
          </div>
          <NavLink className="link" to={`/sandbox/${cur.id}`}>
            <Button>Песочница</Button>
          </NavLink>
        </Drawer>
      )}
    </>
  );
};
