import { Route, Routes } from "react-router-dom";
import { TestPage } from "./Test/TestPage";
import { LoginPage } from "./Login/LoginPage";

export const OuterPage = () => {
  return (
    <Routes>
      <Route path="/test/:user_id/:good_id" element={<TestPage />} />
      <Route path="/test/:user_id" element={<TestPage />} />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};
