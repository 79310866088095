import { FC } from "react";
import "./index.css";
import { Switch, Typography, Space, Row } from "antd";
import { IGood } from "../../types";
import { Card } from "../../../../components/Card";
import { Title } from "../../../../components/Title";

const { Paragraph } = Typography;

export interface IGoodProps extends IGood {
  click: (id: number) => void;
  changeBot: () => void;
}

export const Good: FC<IGoodProps> = ({ id, good_id, bot, title, url, description, click, changeBot }) => {
  const clickGoodTitle = () => {
    window.open(url, "_blank");
  };

  const clickDescription = () => {
    click(id);
  };

  return (
    <Card>
      <Row justify="space-between">
        <Space direction="vertical">
          <Title text={title} onClick={clickGoodTitle} />
          <Typography.Text copyable>{good_id}</Typography.Text>
          <Typography className="link" onClick={clickDescription}>
            Описание и инструкции
          </Typography>
        </Space>
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" checked={bot} onChange={changeBot} />
      </Row>
    </Card>
  );
};
