import { Route, Routes, useNavigate } from "react-router-dom";
import { GoodsPage } from "../Goods/GoodsPage";
import { ChatsPage } from "../Chats/ChatsPage";
import { TemplatesPage } from "../Templates/TemplatesPage";
import { SandboxPage } from "../Sandbox/SandboxPage";
import { SettingsPage } from "../Settings/SettingsPage";
import { FeedPage } from "../Feed/FeedPage";
import { IntegrationsPage } from "../Integrations/IntegrationsPage";
import { ConfigPage } from "../Config/ConfigPage";
import { Button, Layout, Row, Space, Typography } from "antd";
import { Menu } from "./components/Menu/Menu";
import logo from "../../logo.png";
import { LOCAL_STORAGE_LOGIN, LOCAL_STORAGE_NAME } from "../../config/constants";
const { Sider, Content, Header } = Layout;

export const MainPage = () => {
  const history = useNavigate();
  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_NAME);
    history("/login");
  };

  const name = localStorage.getItem(LOCAL_STORAGE_LOGIN);
  
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider>
        <div className="logo-block">
          <img src={logo} className="logo" />
        </div>
        <Row justify="center">
          <Typography.Text strong style={{ color: "white" }}>
            {name}
          </Typography.Text>
        </Row>
        <Menu />
      </Sider>
      <Layout>
        <Header style={{ display: "flex", justifyContent: "end", alignItems: "center", background: "white" }}>
          <Button type="link" onClick={logout}>
            Выйти
          </Button>
        </Header>
        <Content style={{ margin: "0 16px", padding: "30px" }}>
          <div className="wrapper">
            <Routes>
              <Route path="/" element={<FeedPage />} />
              <Route path="/goods" element={<FeedPage />} />
              <Route path="/chats" element={<ChatsPage />} />
              <Route path="/templates" element={<TemplatesPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/config" element={<ConfigPage />} />
              <Route path="/feed" element={<FeedPage />} />
              <Route path="/integrations" element={<IntegrationsPage />} />
              {/* <Route path="/sandbox" element={<SandboxPage />} /> */}
            </Routes>
          </div>
          <Routes>
            <Route path="/sandbox/:good_id" element={<SandboxPage />} />
            <Route path="/sandbox" element={<SandboxPage />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};
