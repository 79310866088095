import axios from "axios";
import { GoodDTO, IGood, IGoodCounts } from "./types";

export const GOODS_API = {
  async getGoods(page: number = 0, text: string = "") {
    try {
      const res = await axios.get(`goods?page=${page}&query=${text}`);
      return res.data
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getGood(id: string) {
    try {
      const res = await axios.get("goods/" + id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getGoodName(user_id: string, good_id: string) {
    try {
      const res = await axios.get(`goods/${user_id}/${good_id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeBotOfGood(good_id: number) {
    try {
      const res = await axios.patch("goods/bot", { good_id });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  //   async changeDescription(good_id: number, description: string) {
  //     try {
  //       const res = await axios.patch("goods", {
  //         good_id,
  //         description,
  //       });
  //       return res.data;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  async changeGood(dto: GoodDTO) {
    try {
      const res = await axios.patch("goods", dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFromAvito() {
    try {
      const res = await axios.get("goods/refresh");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getCounts() {
    try {
      const res = await axios.get<IGoodCounts>(`goods/count`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async turnBotOnForAll() {
    try {
      const res = await axios.patch(`goods/bot/on`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async turnBotOffForAll() {
    try {
      const res = await axios.patch(`goods/bot/off`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
