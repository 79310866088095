import { useState } from "react";

export const useDrawer = <T>() => {
  const [isOpen, setIsOpen] = useState(false);
  const [cur, setCur] = useState<T | null>(null);

  const open = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setCur(null);
  };

  const setCurItem = (item: T) => {
      setCur(item);
      setIsOpen(true);
  }

  return { isOpen, cur, open, onClose, setCurItem };
};
