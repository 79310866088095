import { Button, Drawer, Form, Select, Space, Row, Typography, Switch, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { useDrawer } from "../../../../hooks/useDrawer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { INTEGRATIONS_API } from "../IntegrationsBlock/integrationsApi";
import { MESSENGERS_API } from "./messengersApi";
import { IMessenger } from "./types";
import { MessengerType } from "../IntegrationsBlock/types";
import { AvitoForm } from "./forms/AvitoForm";
import { ApiForm } from "./forms/ApiForm";

export const MessengersBlock = () => {
  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<IMessenger>();
  const [testResult, setTestResult] = useState<any | null>(null);
  const [curType, setCurType] = useState<MessengerType | null>(null);
  const [isFormChanged, setFormChanged] = useState(false);

  const [form] = Form.useForm();

  const { data: messengers, isLoading } = useQuery("messengers", MESSENGERS_API.getMessengers);

  const queryClient = useQueryClient();

  const createMutation = useMutation(MESSENGERS_API.addMessenger, {
    onSuccess: () => {
      queryClient.invalidateQueries("messengers");
    },
  });

  const changeMutation = useMutation(MESSENGERS_API.changeMessenger, {
    onSuccess: () => {
      queryClient.invalidateQueries("messengers");
    },
  });

  const deleteMutation = useMutation(MESSENGERS_API.deleteMessenger, {
    onSuccess: () => {
      queryClient.invalidateQueries("messengers");
    },
  });

  const submitHandler = async (dto: any) => {
    console.log("🚀 ~ file: index.tsx:39 ~ submitHandler ~ dto:", dto);
    if (cur) {
      await changeMutation.mutateAsync({ ...dto, id: cur.id });
    } else {
      await createMutation.mutateAsync(dto);
    }
    onClose();
  };

  // const changeHandler = (values: any) => {
  //   setCurType(values);
  //   // if (values === "avito") {
  //   //   setCurItem
  //   //   // form.setFieldsValue({
  //   //   //   name: "avito",
  //   //   //   label: "Авито",
  //   //   //   url: "https://api.avito.ru/messenger/v2/accounts/{{client_id}}/chats/{{chat_id}}",
  //   //   //   data: `{
  //   //   //     type: "text",
  //   //   //     message: {
  //   //   //       text: {{text}},
  //   //   //     },
  //   //   //   }`,
  //   //   // });
  //   // }
  // };

  const testHandler = async () => {
    const curl = form.getFieldValue("curl");
    const response = await INTEGRATIONS_API.testIntegration(curl);
    setTestResult(response);
  };

  const deleteHandler = async () => {
    if (cur) {
      deleteMutation.mutate(cur.id);
      onClose();
    }
  };

  useEffect(() => {
    if (cur) {
      console.log("🚀 ~ file: index.tsx:85 ~ useEffect ~ cur:", cur)
      form.setFieldsValue(cur);
      setCurType(cur.type as MessengerType);
    } else {
      form.resetFields();
    }
    setFormChanged(false);
  }, [cur]);

  const a = form.getFieldValue("active")
  console.log("🚀 ~ file: index.tsx:95 ~ MessengersBlock ~ a:", a)

  return (
    <div>
      <h3>Интеграции</h3>
      {messengers?.map((item) => (
        <div className="card" onClick={() => setCurItem(item)} key={item.id}>
          <Row justify="space-between">
            <Typography>{item.label}</Typography>
            <Switch checked={item.active} disabled />
          </Row>
        </div>
      ))}
      <Row>
        <Button onClick={open}>Добавить</Button>
      </Row>
      <Drawer title={"Интеграция с платформами"} placement="right" onClose={onClose} open={isOpen} width="60%">
        <Form
          name="basic"
          layout="vertical"
          style={{ maxWidth: 1000 }}
          onFinish={submitHandler}
          form={form}
          autoComplete="off"
          onValuesChange={() => setFormChanged(true)}
        >
          <Form.Item label="Название платформы" name="type" rules={[{ required: true, message: "Необходимо выбрать" }]}>
            <Select
              style={{ width: 600 }}
              onChange={setCurType}
              options={[
                { value: null, label: "" },
                { value: "api", label: "API" },
                { value: "avito", label: "Avito" },
              ]}
            />
          </Form.Item>
          {curType === "avito" ? <AvitoForm /> : curType === "api" ? <ApiForm /> : null}
          {cur && curType === "avito" && (
            <Form.Item label="Статус" name="active">
              <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" checked={form.getFieldValue("active")} />
            </Form.Item>
          )}

          <Form.Item style={{ marginTop: 10 }}>
            <Row justify="space-between">
              <Button type="primary" htmlType="submit" disabled={!isFormChanged}>
                Сохранить
              </Button>
              {cur && (
                <Popconfirm
                  title="Удалить?"
                  description="Уверены?"
                  onConfirm={deleteHandler}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button danger>Удалить</Button>
                </Popconfirm>
              )}
            </Row>
          </Form.Item>
        </Form>
        {testResult && <div className="test_result_block">{JSON.stringify(testResult)}</div>}
      </Drawer>
    </div>
  );
};
