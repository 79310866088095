import axios from "axios";
import { ITemplate } from "./types";

export const TEMPLATES_API = {
  async getTemplates() {
    try {
      const res = await axios.get("templates");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getTemplate(id: string) {
    try {
      const res = await axios.get("templates/" + id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addTemplate(dto: { name: string; text: string }) {
    try {
      const res = await axios.post("templates", dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeTemplate(dto: ITemplate) {
    try {
      const res = await axios.patch("templates", dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeActiveTemplate(id: number) {
    try {
      const res = await axios.patch("templates/active", { id });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getCommonTemplateForGood(id: string | number | undefined) {
    try {
      const res = await axios.get("templates/common/" + id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async deleteTemplate(id: number) {
    try {
      const res = await axios.delete("templates/" + id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
