import { FC } from "react";
import { Space } from "antd";
import { AndroidOutlined, UserOutlined } from "@ant-design/icons";
import "./index.css";

interface Props {
  text: string;
  role: string;
}

export const Message: FC<Props> = ({ text, role }) => {
  return (
    <div className={`message message_${role}`}>
      <Space direction='vertical'>
        <span className="message_role">
          {role === "assistant" ? <AndroidOutlined /> : role === "user" ? <UserOutlined /> : null}
        </span>
        <div>{text}</div>
      </Space>
    </div>
  );
};
