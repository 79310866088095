import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chats } from "../../../components/Chats";
import { SANDBOX_API } from "../../Sandbox/sanboxApi";
import { IAIChat } from "../../Chats/types";
import "./index.css";
import { GOODS_API } from "../../Goods/goodsApi";

export const TestPage = () => {
  const [chats, setChats] = useState<IAIChat[]>([]);
  const [good, setGood] = useState("");
  const [chat_id, setChatId] = useState(0);
  const [pending, setPending] = useState(false);
  const { user_id, good_id } = useParams();

  const getAnswerFromAi = async () => {
    if (user_id) {
      const message = await SANDBOX_API.getAnswerFromAiForTest(chats, user_id, chat_id, good_id);
      if (message) {
        setChats([...chats, message as IAIChat]);
        setPending(false);
      }
    }
  };

  const addMessageHandler = (text: string) => {
    const newMessage = { role: "user", content: text } as IAIChat;
    setChats([...chats, newMessage]);
  };

  useEffect(() => {
    if (chats.length > 0 && chats[chats.length - 1].role === "user" && !pending) {
      setPending(true);
      getAnswerFromAi();
    }
  }, [chats.length]);

  const getGoodName = async () => {
    if (user_id && good_id) {
      const response = await GOODS_API.getGoodName(user_id, good_id);
      if (response) {
        setGood(response);
      }
    }
  };

  const createNewChatId = () => {
    const newChatId = Math.floor(Math.random() * (9999999999 - 100000 + 1)) + 100000;
    setChatId(newChatId);
  };

  const resetChats = () => {
    setChats([]);
    createNewChatId();
  };

  useEffect(() => {
    if (good_id) {
      getGoodName();      
    }
    createNewChatId();
  }, []);

  return (
    <div className="page">
      <div className="App">
        <Chats good={good} chats={chats} pending={pending} resetChats={resetChats} addMessage={addMessageHandler} />
      </div>
    </div>
  );
};
