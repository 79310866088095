import axios from "axios";
import { IIntegration } from "./types";

const URL = "integrations";

export const INTEGRATIONS_API = {
  async getConstants() {
    try {
      const res = await axios.get<{ label: string; value: string }[]>(URL + "/constants");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getIntegrations() {
    try {
      const res = await axios.get(URL);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async addIntegration(dto: IIntegration) {
    try {
      const res = await axios.post(URL, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeIntegration(dto: IIntegration) {
    try {
      const res = await axios.patch(URL, dto);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async testIntegration(dto: any) {
    try {
      const res = await axios.post(URL + "/test", dto);
      return res.data;
    } catch (error: any) {
      console.log('error', error.response.data);
      return error.response.data;
    }
  // async testIntegration(curl: string) {
  //   try {
  //     const res = await axios.post(URL + "/test", { curl });
  //     return res.data;
  //   } catch (error: any) {
  //     console.log('error', error.response.data);
  //     return error.response.data;
  //   }
  },
  // async getTemplate(id: string) {
  //   try {
  //     const res = await axios.get("templates/" + id);
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // async addTemplate(dto: { name: string; text: string }) {
  //   try {
  //     const res = await axios.post("templates", dto);
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // async changeTemplate(dto: ITemplate) {
  //   try {
  //     const res = await axios.patch("templates", dto);
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // async changeActiveTemplate(id: number) {
  //   try {
  //     const res = await axios.patch("templates/active", { id });
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // async getCommonTemplateForGood(id: string) {
  //   try {
  //     const res = await axios.get("templates/common/" + id);
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  // async deleteTemplate(id: number) {
  //   try {
  //     const res = await axios.delete("templates/" + id);
  //     return res.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
};
