import { useEffect, useState } from "react";
import { Message } from "../../components/Message";
import { TEMPLATES_API } from "../Templates/templatesApi";
import { Button, Input, Space, Typography, Row } from "antd";
import { IGood } from "../Goods/types";
import { GOODS_API } from "../Goods/goodsApi";
import { SANDBOX_API } from "./sanboxApi";
import { IAIChat } from "../Chats/types";
import "./index.css";
import { DOMAIN } from "../../config/api";
import { useParams } from "react-router-dom";
import { LOCAL_STORAGE_ID } from "../../config/constants";

export const SandboxPage = () => {
  const [chats, setChats] = useState<IAIChat[]>([]);
  const [text, setText] = useState("");
  const [pending, setPending] = useState(false);
  const [context, setContext] = useState("");
  const [goodText, setGoodText] = useState("");
  const [good, setGood] = useState<IGood | null>(null);

  const { good_id } = useParams();

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newMessage = { role: "user", content: text } as IAIChat;
      setChats([...chats, newMessage]);
      setText("");
    }
  };

  const saveGood = async () => {
    const response = await GOODS_API.getGood(goodText);
    if (response) {
      setGood(response);
    }
  };

  const handleGoodKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await saveGood();
    }
  };

  const getAnswerFromAi = async () => {
    // const commonContext = context + good?.description;
    const message = await SANDBOX_API.getAnswerFromAiTest(chats);
    // const message = await SANDBOX_API.getAnswerFromAi(chats, commonContext);
    if (message) {
      setChats([...chats, message as IAIChat]);
      setPending(false);
    }
  };

  useEffect(() => {
    if (chats.length > 0 && chats[chats.length - 1].role === "user" && !pending) {
      setPending(true);
      getAnswerFromAi();
    }
  }, [chats.length]);

  const getInitialContext = async () => {
    const response = await TEMPLATES_API.getCommonTemplateForGood(good?.id);
    if (response) {
      setContext(response);
    }
  };

  useEffect(() => {
    getInitialContext();
  }, []);

  const user_id = localStorage.getItem(LOCAL_STORAGE_ID);

  return (
    <div className="sandbox_wrapper">
      <div className="sandbox_context sandbox_block">
        <Typography.Paragraph copyable={{ tooltips: false }}>{`https://haggle.cloud/test/${user_id}`}</Typography.Paragraph>
        <h3>Контекст</h3>
        {good ? (
          <Space direction="vertical" style={{ width: "100%", border: "1px solid lightgray", padding: 10 }}>
            <p>{good.title}</p>
            <Typography.Text copyable>{`${DOMAIN}/test/${good.user_id}/${good.good_id}`}</Typography.Text>
          </Space>
        ) : (
          <Space.Compact style={{ width: "100%" }}>
            <Input
              placeholder="id товара"
              onChange={(e) => setGoodText(e.target.value)}
              onKeyDown={handleGoodKeyDown}
            />
            <Button type="primary" onClick={saveGood}>
              Сохранить
            </Button>
          </Space.Compact>
        )}
        <div>{context}</div>
        {good && <div>{good.description}</div>}
      </div>
      <div className="sandbox_block">
        <h3>Чат</h3>
        {chats.map((item) => (
          <Message text={item.content} role={item.role} />
        ))}
        {pending && <div className="chat_item chat_item_assistant">...</div>}
        <div>
          <Input value={text} onChange={(e) => setText(e.target.value)} onKeyDown={handleKeyDown} />
        </div>
        {chats.length > 0 && <div onClick={() => setChats([])}>Новый</div>}
      </div>
    </div>
  );
};
