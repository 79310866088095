import { Button, Form, Input, Switch } from "antd";
import { useQuery } from "react-query";
import { USER_API } from "../Outer/Login/userApi";
import { useEffect } from "react";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

export const SettingsPage = () => {
  const { data, isLoading, error } = useQuery<any>(
    "user",
    USER_API.getUserSettings
  );
  
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    await USER_API.changeUserSettings(values);
  };

  const turnOnHooks = async () => {
    await USER_API.turnHookOn(data.avito);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const isReadyForTurnHooksOn =
    data && data.api_id && data.api_secret && data.avito;

  return (
    <Form
      name="basic"
      form={form}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Form.Item<FieldType>
        label="API client ID"
        name="api_id"
        rules={[{ required: true, message: "Please input your client ID!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label="API client secret"
        name="api_secret"
        rules={[{ required: true, message: "Please input your client secret!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label="AVITO ID"
        name="avito"
        rules={[{ required: true, message: "Please input your avito_id!" }]}
      >
        <Input />
      </Form.Item>
      {isReadyForTurnHooksOn && (
        <Form.Item<FieldType> label="Вебхуки" name="hooks">
          <Switch
            checkedChildren="Вкл"
            unCheckedChildren="Выкл"
            checked={data.hooks}
            onChange={turnOnHooks}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};
