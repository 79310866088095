import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Drawer, Form, Input, Select, Space } from "antd";
import { FEED_API } from "../feedApi";
import { useDrawer } from "../../../hooks/useDrawer";
import { IUrl, UrlType } from "../types";

export const UrlsBlock = () => {
  const [curType, setCurType] = useState<UrlType | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<IUrl>();
  const { data: url, isLoading, error } = useQuery("urls", FEED_API.getUrl);
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const createMutation = useMutation(FEED_API.addUrl, {
    onSuccess: () => {
      queryClient.invalidateQueries("urls");
    },
  });

  const changeMutation = useMutation(FEED_API.changeUrl, {
    onSuccess: () => {
      queryClient.invalidateQueries("urls");
    },
  });

  const submitHandler = async (dto: any) => {
    if (cur) {
      await changeMutation.mutateAsync({ ...dto, id: cur.id });
    } else {
      await createMutation.mutateAsync(dto);
    }
    onClose();
  };

  const refresh = async () => {
    setIsRefreshing(true)
    await FEED_API.refresh();
    setIsRefreshing(false)
  };

  return (
    <>
      <div>
        <h3>Ссылка обновления цен и остатков</h3>
        <div>
          {url ? (
            <div className="card" >
              <div onClick={() => setCurItem(url)}>{url.url}</div>
              <Button onClick={refresh} loading={isRefreshing}>Обновить</Button>
            </div>
          ) : (
            <Button onClick={open}>Добавить</Button>
          )}
        </div>
      </div>
      <Drawer title={"Интеграция с платформами"} placement="right" onClose={onClose} open={isOpen} width="60%">
        <Form
          name="basic"
          layout="vertical"
          style={{ maxWidth: 1000 }}
          onFinish={submitHandler}
          form={form}
          autoComplete="off"
        >
          <Form.Item label="URL" name="url" rules={[{ required: true, message: "Please input" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Тип данных" name="type" rules={[{ required: true, message: "Please input" }]}>
            <Select
              style={{ width: 600 }}
              onChange={setCurType}
              options={[
                { value: "xlsx", label: "xlsx" },
                { value: "yml", label: "yml" },
              ]}
            />
          </Form.Item>
          {curType === "xlsx" && (
            <>
              <Form.Item label="ID" name="good_id" rules={[{ required: true, message: "Please input" }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Стоимость" name="price" rules={[{ required: true, message: "Please input" }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Наличие" name="stock" rules={[{ required: true, message: "Please input" }]}>
                <Input />
              </Form.Item>
            </>
          )}
          <Form.Item style={{ marginTop: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit" >
                Сохранить
              </Button>
              {/* <Button onClick={testHandler}>Протестировать</Button> */}
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
