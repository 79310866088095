import { Button, Drawer } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Template } from "./components/Template";
import { useDrawer } from "../../hooks/useDrawer";
import { TEMPLATES_API } from "./templatesApi";
import { TemplateForm } from "./components/TemplateForm";
import { ITemplate } from "./types";

export const TemplatesPage = () => {
  const { data, isLoading, error } = useQuery<ITemplate[]>(
    "templates",
    TEMPLATES_API.getTemplates
  );

  const queryClient = useQueryClient();

  const createMutation = useMutation(TEMPLATES_API.addTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries("templates");
    },
  });
  const changeMutation = useMutation(TEMPLATES_API.changeTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries("templates");
    },
  });
  const changeActveMutation = useMutation(TEMPLATES_API.changeActiveTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries("templates");
    },
  });
  const deleteMutation = useMutation(TEMPLATES_API.deleteTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries("templates");
    },
  });

  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<ITemplate>();

  const clickHandler = (id: number) => {
    const curItem = data?.find((item) => item.id === id);
    curItem && setCurItem(curItem);
  };

  const submitHandler = async (dto: any) => {
    if (cur) {
      // await API.changeTemplate({ ...dto, id: cur.id });
      await changeMutation.mutateAsync({ ...dto, id: cur.id });
    } else {
      await createMutation.mutateAsync(dto);
    }
    onClose();
  };

  const activeHandler = async (id: number) => {
    await changeActveMutation.mutateAsync(id);
  };

  const deleteHandler = async (id: number) => {
    await deleteMutation.mutateAsync(id);
  };

  if (isLoading) return null;

  return (
    <div>
      {data?.map((template) => (
        <Template
          key={template.id}
          {...template}
          click={clickHandler}
          change={activeHandler}
          destroy={deleteHandler}
        />
      ))}
      {isOpen && (
        <Drawer
          title={cur ? cur.name : "Новый"}
          placement="right"
          onClose={onClose}
          open={isOpen}
          width="60%"
        >
          <TemplateForm initialValues={cur} submit={submitHandler} />
        </Drawer>
      )}
      <Button onClick={open}>Добавить</Button>
    </div>
  );
};
