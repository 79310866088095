import { useState } from "react";
import { Button, Form, Input } from "antd";
import { USER_API } from "./userApi";
import { LOCAL_STORAGE_ID, LOCAL_STORAGE_LOGIN, LOCAL_STORAGE_NAME } from "../../../config/constants";
import "./index.css";

export const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const onFinish = async (values: any) => {
    const user = isLogin ? await USER_API.login(values) : await USER_API.registration(values);
    if (user) {
      localStorage.setItem(LOCAL_STORAGE_NAME, user.token);
      localStorage.setItem(LOCAL_STORAGE_ID, user.user_id);
      localStorage.setItem(LOCAL_STORAGE_LOGIN, values.login);
      window.location.reload();
    }
  };

  return (
    <div className="login_block">
      <h2>{isLogin ? "Авторизация" : "Регистрация"}</h2>
      <Form
        name="basic"
        layout="vertical"
        style={{ maxWidth: 400 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Логин" name="login" rules={[{ required: true, message: "Введите логин" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Пароль" name="password" rules={[{ required: true, message: "Введите пароль" }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {isLogin ? "Войти" : "Зарегистрировать"}
          </Button>
        </Form.Item>
      </Form>
      <div onClick={() => setIsLogin(!isLogin)} className="button_hidden">
        {isLogin ? "Регистрация" : "Авторизация"}
      </div>
    </div>
  );
};
