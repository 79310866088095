import { useEffect, useState } from "react";
import { LoginPage } from "./pages/Outer/Login/LoginPage";
import { MainPage } from "./pages/Main/MainPage";
import { LOCAL_STORAGE_NAME } from "./config/constants";
import "./config/api";
import "./App.css";
import { OuterPage } from "./pages/Outer/OuterPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_NAME);
    if (token) {
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) return null;

  return isLoggedIn ? <MainPage /> : <OuterPage />;
}

export default App;
