import axios from "axios";

export const CHAT_API = {
  async getChats(page: number) {
    try {
      const res = await axios.get(`chats?page=${page}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getChat(chat_id: number) {
    try {
      const res = await axios.get("chats/" + chat_id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeBotOfChat(chat_id: number) {
    try {
      const res = await axios.patch("chats/bot", { chat_id });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },

  async getOrdersCount() {
    try {
      const res = await axios.get("chats/orders");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async processOrder(chat_id: number) {
    try {
      const res = await axios.patch("chats/order", { chat_id });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
