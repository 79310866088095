import axios from "axios";
import { IAIChat } from "../Chats/types";

export const SANDBOX_API = {
  async getAnswerFromAi(chats: IAIChat[], context: string) {
    try {
      const res = await axios.post("ai", { chats, context });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getAnswerFromAiTest(chats: IAIChat[]) {
    try {
      const res = await axios.post("ai/sandbox", { chats });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getAnswerFromAiForTest(chats: IAIChat[], user_id: string, chat_id: string | number, good_id: string | undefined) {
    try {
      const res = await axios.post("ai/test", { chats, user_id, good_id, chat_id });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
