import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CONGIG_API } from "./configApi";
import { Button, Drawer, Form, Input, Space } from "antd";
import { useDrawer } from "../../hooks/useDrawer";
import { IAction } from "./types";
const { TextArea } = Input;

export const ConfigPage = () => {
  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<IAction>();
  const { data: actions, isLoading, error } = useQuery("actions", CONGIG_API.getActions);
  const queryClient = useQueryClient();
  const changeMutation = useMutation(CONGIG_API.changeTextOfAction, {
    onSuccess: () => {
      queryClient.invalidateQueries("actions");
    },
  });
  const [form] = Form.useForm();
  const submitHandler = async (dto: any) => {
    if (cur) {
      await changeMutation.mutateAsync({ id: cur.id, text: dto.text });
    }
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(cur);
  }, [cur]);

  return (
    <>
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        {actions?.map((action) => (
          <div className="card" onClick={() => setCurItem(action)}>
            <h3>{action.desc}</h3>
            <div>{action.text}</div>
          </div>
        ))}
      </Space>

      <Drawer title={"Ответ от бота"} placement="right" onClose={onClose} open={isOpen} width="60%">
        <Form form={form} onFinish={submitHandler} layout="vertical">
          <Form.Item name="text" label="Текст сообщения">
            <TextArea autoSize />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
