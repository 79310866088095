import { Button, Drawer, Form, Select, Input, Space, Tag, Row, Typography, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDrawer } from "../../../../hooks/useDrawer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { INTEGRATIONS_API } from "./integrationsApi";
import { IIntegration } from "./types";
import { Card } from "../../../../components/Card";
const { TextArea } = Input;

export const IntegrationsBlock = () => {
  const { cur, isOpen, open, onClose, setCurItem } = useDrawer<IIntegration>();
  const [testResult, setTestResult] = useState<any | null>(null);

  const [form] = Form.useForm();

  const { data: constants } = useQuery("constants", INTEGRATIONS_API.getConstants);

  const { data: integrations, isLoading } = useQuery<IIntegration[]>("integrations", INTEGRATIONS_API.getIntegrations);

  const queryClient = useQueryClient();

  const createMutation = useMutation(INTEGRATIONS_API.addIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries("integrations");
    },
  });

  const changeMutation = useMutation(INTEGRATIONS_API.changeIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries("integrations");
    },
  });

  const submitHandler = async (dto: any) => {
    if (cur) {
      await changeMutation.mutateAsync({ ...dto, id: cur.id });
    } else {
      await createMutation.mutateAsync(dto);
    }
    onClose();
  };

  const testHandler = async () => {
    // const curl = form.getFieldValue("curl");
    const values = form.getFieldsValue();
    const response = await INTEGRATIONS_API.testIntegration(values);
    setTestResult(response);
  };

  useEffect(() => {
    if (cur) {
      form.setFieldsValue(cur);
    } else {
      form.resetFields();
    }
  }, [cur]);

  return (
    <div>
      <h3>Оповещение о заказах</h3>

      {integrations?.map((item) => (
        <div className="card" onClick={() => setCurItem(item)} key={item.id}>
          <Row justify="space-between">
            <Typography>{item.name}</Typography>
            <Switch checked={item.active} disabled />
          </Row>
        </div>
      ))}
      <Row>
        <Button onClick={open}>Добавить</Button>
      </Row>
      <Drawer title={"Интеграция с платформами"} placement="right" onClose={onClose} open={isOpen} width="60%">
        <Form
          name="basic"
          layout="vertical"
          style={{ maxWidth: 1000 }}
          onFinish={submitHandler}
          form={form}
          autoComplete="off"
        >
          <Form.Item label="Название платформы" name="name" rules={[{ required: true, message: "Please input" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="URL" name="url" rules={[{ required: true, message: "Please input" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Тип данных" name="type" rules={[{ required: true, message: "Please input" }]}>
          <Select
              style={{ width: 600 }}
              // onChange={setCurType}
              options={[
                { value: "json", label: "json" },
                { value: "form-data", label: "form-data" },
              ]}
            />
          </Form.Item>
          <Form.Item label="Данные" name="data">
            <TextArea rows={10} />
          </Form.Item>
          <Space direction="vertical">
            <div>Вы можете использовать следующие перемненые в запросе:</div>
            <Space>
              {constants?.map((item) => (
                <Tooltip title={item.label}>
                  <Tag>{item.value}</Tag>
                </Tooltip>
              ))}
            </Space>
          </Space>
          <Form.Item style={{ marginTop: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
              <Button onClick={testHandler}>Протестировать</Button>
            </Space>
          </Form.Item>
        </Form>
        {testResult && <div className="test_result_block">{JSON.stringify(testResult)}</div>}
      </Drawer>
    </div>
  );
};
