import { Form, Input, Space, Tag, Tooltip } from "antd";
import { useQuery } from "react-query";
import { MESSENGERS_API } from "../messengersApi";
const { TextArea } = Input;

export const ApiForm = () => {
    const { data: constants } = useQuery("constants", MESSENGERS_API.getConstants);
  return (
    <>
      <Form.Item style={{ marginTop: 10 }} name="client_id" label="ID интеграции" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} name="name" label="Имя интеграции" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} name="label" label="Название интеграции" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} name="url" label="url" rules={[{ required: true, message: "Необходимо указать" }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ marginTop: 10 }} name="data" label="Данные" rules={[{ required: true, message: "Необходимо указать" }]}>
        <TextArea rows={6} />
      </Form.Item>
      <Space direction="vertical">
        <div>Вы можете использовать следующие перемненые в запросе:</div>
        <Space>
          {constants?.map((item) => (
            <Tooltip title={item.label}>
              <Tag>{item.value}</Tag>
            </Tooltip>
          ))}
        </Space>
      </Space>
    </>
  );
};
