import { FC, useState } from "react";
import { IAIChat } from "../../pages/Chats/types";
import { Message } from "../Message";
import { Button, Input, Row, Space, Spin } from "antd";
import { SendOutlined } from "@ant-design/icons";
import "./index.css";

interface Props {
  chats: IAIChat[];
  pending: boolean;
  good: string;
  addMessage: (text: string) => void;
  resetChats: () => void;
}

export const Chats: FC<Props> = ({ chats, pending, good, addMessage, resetChats }) => {
  const [text, setText] = useState("");

  const sendMessage = () => {
    if (pending) return;
    addMessage(text);
    setText("");
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="chat_wrapper">
      <div className="chat_chats">
        <Message
          text={`Я менеджер интернет-магазина, который продаёт товар "${good}". \nЗадай любой вопрос, чтобы увидеть возможности искусственного интеллекта. `}
          role="assistant"
        />
        {chats.map((item) => (
          <Message text={item.content} role={item.role} />
        ))}
        {pending && (
          <Spin size="small">
            <div className="content" />
          </Spin>
        )}
      </div>
      <div className="chat_inputs">
        <Space.Compact style={{ width: "100%" }}>
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            style={{ outline: "none", border: "none" }}
            placeholder="Ваше сообщение"
          />
          <Button onClick={sendMessage} disabled={pending}>
            <SendOutlined />
          </Button>
        </Space.Compact>

        {chats.length > 0 && (
          <Row justify="center" style={{ marginTop: 20 }}>
            <Button onClick={resetChats} type="link">
              Новый разговор
            </Button>
          </Row>
        )}
      </div>
    </div>
  );
};
