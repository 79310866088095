import axios from "axios";
import { IAction } from "./types";

export const CONGIG_API = {
  async getActions() {
    try {
      const res = await axios.get<IAction[]>(`actions`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  async changeTextOfAction({ id, text }: { id: number; text: string }) {
    try {
      const res = await axios.patch(`actions`, { id, text });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
